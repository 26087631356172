import { type NavItem, setActiveValueInNavItems, VerticalNavbar } from '@nike/eds'
import { NikeApp } from '@nike/nike-design-system-icons'
import { AuthRole } from 'auth/const'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { type RootState, useAppSelector } from 'redux/store'

import { mawmProject, nasProject } from './ProjectLogoItems'
import { adminItem, executionItem, historyItem, instanceConfigItem, type CustomNavItem, workflowItem } from './SidebarItems'

import './sidebar.css'

const userSelector = (state: RootState) => state.user

export function SideBar () {
  const projectName = process.env.REACT_APP_PROJECT_NAME
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useAppSelector(userSelector)
  const hasRoleChanged = useRef(false)

  const [items, setItems] = useState<CustomNavItem[]>([])
  const [projectLogo, setProjectLogo] = useState<NavItem[]>([])

  useEffect(() => {
    if (user) {
      if (user.canUse(AuthRole.SUPPORT)) {
        setItems([executionItem, workflowItem, historyItem, instanceConfigItem, adminItem])
      } else if (user.canUse(AuthRole.STANDARD)) {
        setItems([executionItem, workflowItem])
      } else if (user.hasAnyRole()) {
        setItems([executionItem, workflowItem, historyItem, instanceConfigItem])
      } else {
        setItems([])
      }
      hasRoleChanged.current = true
    }
  }, [user])

  useEffect(() => {
    if (hasRoleChanged.current) {
      changeIconBasedOnLocation()
      hasRoleChanged.current = false
    }
  }, [items, location])

  useEffect(() => {
    if (projectName === 'mawm') {
      setProjectLogo([mawmProject])
    } else if (projectName === 'nas') {
      setProjectLogo([nasProject])
    }
  }, [projectName])

  // check location on items id if location has id in url
  const changeIconBasedOnLocation = () => {
    for (const item of items) {
      if (item.paths.some(path => location.pathname.includes(path))) {
        setActiveIcon(item.id)
      }
    }
  }

  const handleClick = (item: { id: string }) => {
    navigate('/' + item.id)
  }

  const setActiveIcon = (id: string) => {
    const newItems = setActiveValueInNavItems(id, items)
    if (newItems) { setItems(newItems as CustomNavItem[]) }
  }

  return (
    <div style={{ position: 'fixed', height: '100%' }}>
      <VerticalNavbar
        navLogoSlot={<NikeApp width={'56px'} height={'56px'} data-testid={'hawk-eye-logo'} />}
        appName='HawkEye'
        panelLogoSlot={
          <img
            alt="Placeholder Logo Image"
            src="https://unsplash.com/photos/R_0rTS9ENnk/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjQ0MjY4ODM1"
            style={{ objectFit: 'cover' }}
            height="48px"
            width="48px"
          />
        }
        // appSubtitle="Your AWM workflow manager"
        onNavigate={handleClick}
        items={items}
        bottomItems={projectLogo}
      />
    </div>
  )
}
